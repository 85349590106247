import { action } from "@/types/general";

export const formAction = <T>(
  actionFoo: (
    formData: FormData,
  ) => Promise<
    | { errors: Record<keyof T, string>; data?: undefined }
    | { data: T; errors?: undefined }
    | undefined
  >,
  cb: (data: T, action: action) => void,
  action: action,
) => {
  return async (formData: FormData) => {
    const result = await actionFoo(formData);
    if (result?.errors) {
      return result?.errors;
    }
    cb(result?.data!, action);
  };
};
