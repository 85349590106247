import { useCallback, useState } from "react";

export const useModified = <T extends {}>(initialData: T) => {
  const [modified, setModified] = useState(new Set());

  const handleModifyingCheck = useCallback(
    (
      key: keyof T,
      value: boolean | string | number | Array<string | number>,
    ) => {
      let isModified: boolean;

      if (typeof value === "object") {
        isModified = JSON.stringify(initialData[key]) !== JSON.stringify(value);
      } else {
        isModified = initialData[key] !== value;
      }

      setModified((state) => {
        const alreadyModified = state.has(key);
        if (isModified && !alreadyModified) {
          return new Set(state.add(key));
        } else if (alreadyModified && !isModified) {
          state.delete(key);
          return new Set(state);
        }

        return state;
      });
    },
    [initialData],
  );

  return [!!modified.size, handleModifyingCheck] as [
    boolean,
    (
      key: keyof T,
      value: boolean | string | number | Array<string | number>,
    ) => void,
  ];
};
