import useSWR from "swr";
import { ValidationError } from "yup";
import { useAlert } from "@/context/AlertContext";
import { useApi } from "@/lib/api";
import { userSchema } from "@/lib/validations/user";
import { User } from "@/types";

export const useUsers = () => {
  const api = useApi();
  const alert = useAlert();

  const getUserList = async () => {
    const response = await api.get<User[]>("/users");
    return response?.data;
  };
  const { data: users, error: userErrors } = useSWR(
    ["users"],
    getUserList,
    {
      onError: (e: any) => alert('error', e, e?.response?.data?.status),
    }
  );
  return { users, userErrors };
};

export const useCurrentUser = () => {
  const api = useApi();

  const getMe = async () => {
    const { data } = await api.get<User>("/users/me");
    return data;
  };

  return { getMe };
};

export const useMutateUser = () => {
  const api = useApi();
  const alert = useAlert();

  const createUser = async (formData: FormData) => {
    const { data, errors } = await manageUserData(formData);
    if (errors) {
      return { errors };
    }
    try {
      const response = await api.post("/users", {
        name: data.name,
        email: data.email,
        role_id: +data.role,
        clients_ids: data.clients!.map((id: string) => +id),
        regions_ids: data.regions!.map((id: string) => +id),
        is_active: data.is_active,
      });
      alert('success', 'User successfully created');
      return { data: response.data };
    } catch (e: any) {
      console.error("Error", JSON.stringify(e));
      if (e.response.status === 400) {
        return {
          errors: {
            email:
              "The User already exists. Please, try to create a user with a different email.",
          } as Record<keyof User, string>,
        };
      }
      alert('error', e, e?.response?.data?.status);
    }
  };

  const editUser = async (formData: FormData) => {
    const { data, errors } = await manageUserData(formData);
    if (errors) {
      return { errors };
    }
    try {
      const response = await api.patch(`/users/${data.id}`, {
        name: data.name,
        email: data.email,
        role_id: +data.role,
        clients_ids: data.clients!.map((id: string) => +id),
        regions_ids: data.regions!.map((id: string) => +id),
        is_active: data.is_active,
      });
      alert('success', 'User data successfully updated');
      return { data: response.data };
    } catch (e: any) {
      console.error("Error", JSON.stringify(e));
      if (e.response.status === 400) {
        return {
          errors: {
            email:
              "The User already exists. Please, try to create a user with a different email.",
          } as Record<keyof User, string>,
        };
      }
      alert('error', e, e?.response?.data?.status);
    }
  };

  const activateToggle = async (id: number, value: boolean) => {
    try {
      const { data } = await api
        .patch(`/users/${id}`, { is_active: value })
        .then((res) => res.data);
      alert('success', `User successfully ${value ? 'activated' : 'deactivated'}`);
      return { data };
    } catch (e: any) {
      console.error("Error", e);
      alert('error', e, e?.response?.data?.status);
    }
  };

  return {
    activateToggle,
    createUser,
    editUser,
  };
};

const manageUserData = async (formData: FormData) => {
  try {
    const data = {
      id: formData.get("id"),
      name: (formData.get("name") as string).trim(),
      email: (formData.get("email") as string).trim(),
      role: formData.get("role") || "",
      clients: (formData.get("clients") as string)
        ? (formData.get("clients") as string).split(",")
        : [],
      regions: (formData.get("regions") as string)
          ? (formData.get("regions") as string).split(",")
          : [],
      is_active: !!(formData.get("is_active") === "on"),
    };
    const validatedData = await userSchema.validate(data, {
      abortEarly: false,
    });
    return { data: validatedData };
  } catch (e: unknown) {
    const errors = (e as ValidationError).inner.reduce(
      (acc, curr) => {
        const key = curr.path as keyof User;
        acc[key] = curr.message;
        return acc;
      },
      {} as Record<keyof User, string>,
    );
    return { errors };
  }
};
