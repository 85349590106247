import useSWR, { useSWRConfig } from "swr";
import { ValidationError } from "yup";
import { useAlert } from "@/context/AlertContext";
import { useApi } from "@/lib/api";
import { Region } from "@/types";
import { regionSchema } from "@/lib/validations/region";

export const useRegions = () => {
  const api = useApi();
  const alert = useAlert();

  const getRegionList = async () => {
    const response = await api.get<Region[]>("/regions");
    return response?.data;
  };

  const { data: regions, error: regionErrors } = useSWR(
    ["regions"],
      getRegionList,
    {
      onError: (e: any) => alert('error', e, e?.response?.data?.status),
    }
  );

  return { regions, regionErrors };
};

export const useMutateRegion = () => {
  const api = useApi();
  const alert = useAlert();
  const { mutate } = useSWRConfig();

  const createRegion = async (formData: FormData) => {
    const { data, errors } = await manageRegionData(formData);
    if (errors) {
      return { errors };
    }
    try {
      const response = await api.post("/regions", {
        name: data.name,
        code: data.code,
        description: data.description,
      });
      mutate(["regions"]);
      mutate(["users"]);
      alert('success', 'Region successfully created');
      return { data: response.data };
    } catch (e: any) {
      console.error("Error", JSON.stringify(e));
      if (e.response.status === 400) {
        return {
          errors: { name: "Region name and code should be unique" } as Record<
            keyof Region,
            string
          >,
        };
      }
      alert('error', e, e?.response?.data?.status);
    }
  };

  const editRegion = async (formData: FormData) => {
    const { data, errors } = await manageRegionData(formData);
    if (errors) {
      return { errors };
    }
    try {
      const response = await api.patch(`/regions/${data.id}`, {
        name: data.name,
        code: data.code,
        description: data.description,
      });
      mutate(["regions"]);
      alert('success', 'Region data successfully updated');
      return { data: response.data };
    } catch (e: any) {
      console.error("Error", JSON.stringify(e));
      if (e.response.status === 400) {
        return {
          errors: { name: "Region name and code should be unique" } as Record<
            keyof Region,
            string
          >,
        };
      }
      alert('error', e, e?.response?.data?.status);
    }
  };

  const deleteRegion = async (id: number) => {
    try {
      await api.delete(`/regions/${id}`);
      mutate(["regions"]);
      mutate(["users"]);
      alert('success', 'Region successfully deleted');
    } catch (e: any) {
      console.error("Error", e);
      alert('error', e, e?.response?.data?.status);
    }
  };

  return { editRegion, createRegion , deleteRegion };
};

const manageRegionData = async (formData: FormData) => {
  try {
    const data = {
      id: formData.get("id"),
      name: (formData.get("name") as string).trim(),
      code: (formData.get("code") as string).trim(),
      description: (formData.get("description") as string).trim(),
    };
    const validatedData = await regionSchema.validate(data, {
      abortEarly: false,
    });
    return { data: validatedData as Region };
  } catch (e: unknown) {
    const errors = (e as ValidationError).inner.reduce(
      (acc, curr) => {
        const key = curr.path as keyof Region;
        acc[key] = curr.message;
        return acc;
      },
      {} as Record<keyof Region, string>,
    );
    return { errors };
  }
};
