import React from "react";
import useSwr from "swr";
import { useStore } from "@/context/headerContext";

type source = "users" | "clients" | "regions";

export const useUpdateHeaderData = (
  source: source,
  actions: React.ReactNode,
) => {
  const [_, setAction] = useStore((state) => state.actions);
  useSwr(["update-header-data", source], () => setAction({ actions }));
};

export const useUpdateHeaderTitle = (title: string) => {
  const [_, setTitle] = useStore((state) => state.title);
  useSwr(["update-header-title", title], () => setTitle({ title }));
};
