import { useState } from "react";
import { useModified } from "@/hooks/useModified";
import { useMutateClient } from "@/hooks/useClients";
import { formAction } from "@/lib/helper/formAction";
import { Sidebar, Select, TextArea, TextInput, Button } from "@/components";
import { Client, SelectOption, action } from "@/types";
import styles from "./style.module.scss";

interface FormState extends Omit<Client, "id"> {
  title: string;
  action: (data: FormData) => Promise<any>;
  id?: number | string;
}

interface Props {
  client?: Client | null;
  industries: SelectOption<string>[];
  onSubmit: (data: Client, operation: action) => void;
  onClose: () => void;
}

interface ComponentProps {
  data: FormState;
  industries: SelectOption<string>[];
  handleChange: (
    key: keyof FormState,
    value: boolean | string | number | Array<string | number>,
  ) => void;
}

export const ClientSidebar = ({
  client,
  onClose,
  onSubmit,
  ...props
}: Props) => {
  const { editClient, createClient } = useMutateClient();
  const data: FormState = client
    ? {
        title: "Edit Client",
        action: formAction(editClient, onSubmit, "edit"),
        ...client,
        industry:
          props.industries.find(
            (industry) => industry.label === client.industry,
          )?.value || "",
      }
    : {
        title: "New Client",
        action: formAction(createClient, onSubmit, "create"),
        name: "",
        company_size: undefined,
        industry: "",
        description: "",
      };

  const [modified, onChange] = useModified(data);

  return (
    <Sidebar onClose={onClose} modified={modified}>
      <article className={styles.wrapper}>
        <Content {...props} data={data} handleChange={onChange} />
      </article>
    </Sidebar>
  );
};

const Content = ({ data, handleChange, industries }: ComponentProps) => {
  const [errors, setError] = useState<Record<keyof FormState, string>>(
    {} as Record<keyof FormState, string>,
  );

  const onChangeHandle = (
    key: keyof FormState,
    value: boolean | string | number | Array<string | number>,
  ) => {
    handleChange(key, value);
    setError((errorState) => {
      const clone = { ...errorState };
      delete clone[key];
      return clone;
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const validationErrors = await data.action(formData);
    if (validationErrors) {
      setError(validationErrors);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <header>
        <Button type="submit">Save</Button>
        <h2 className={styles.title}>{data.title}</h2>
      </header>
      <div className={styles.content}>
        <input type="text" readOnly hidden value={data.id} id="id" name="id" />
        <TextInput
          label="Client Name"
          name="name"
          required
          defaultValue={data.name}
          error={!!errors?.name}
          helperText={errors?.name}
          onChange={(e) => onChangeHandle("name", e.target.value)}
        />
        <div className={styles.row}>
          <TextInput
            label="Company Size"
            name="company_size"
            type="number"
            defaultValue={data.company_size}
            error={!!errors?.company_size}
            helperText={errors?.company_size}
            onChange={(e) => onChangeHandle("company_size", e.target.value)}
          />
          <Select
            label="Industry"
            name="industry"
            defaultValue={data.industry}
            options={industries}
            error={!!errors?.industry}
            helperText={errors?.industry}
            onChange={(value) => onChangeHandle("industry", value)}
          />
        </div>
        <TextArea
          label="Description"
          name="description"
          rows={4}
          defaultValue={data.description}
          error={!!errors?.description}
          helperText={errors?.description}
          onChange={(e) => onChangeHandle("description", e.target.value)}
        />
      </div>
    </form>
  );
};
