import useSWR, { useSWRConfig } from "swr";
import { ValidationError } from "yup";
import { useAlert } from "@/context/AlertContext";
import { useApi } from "@/lib/api";
import { Client } from "@/types";
import { clientSchema } from "@/lib/validations/client";

export const useClients = () => {
  const api = useApi();
  const alert = useAlert();

  const getClientList = async () => {
    const response = await api.get<Client[]>("/clients");
    return response?.data;
  };

  const { data: clients, error: clientErrors } = useSWR(
    ["clients"],
    getClientList,
    {
      onError: (e: any) => alert('error', e, e?.response?.data?.status),
    }
  );

  return { clients, clientErrors };
};

export const useMutateClient = () => {
  const api = useApi();
  const alert = useAlert();
  const { mutate } = useSWRConfig();

  const createClient = async (formData: FormData) => {
    const { data, errors } = await manageClientData(formData);
    if (errors) {
      return { errors };
    }
    try {
      const response = await api.post("/clients", {
        name: data.name,
        company_size: data.company_size ? +data.company_size : null,
        industry_id: data.industry ? +data.industry : null,
        description: data.description,
      });
      mutate(["clients"]);
      mutate(["users"]);
      alert('success', 'Client successfully created');
      return { data: response.data };
    } catch (e: any) {
      console.error("Error", JSON.stringify(e));
      if (e.response.status === 400) {
        return {
          errors: { name: "Client name should be unique" } as Record<
            keyof Client,
            string
          >,
        };
      }
      alert('error', e, e?.response?.data?.status);
    }
  };

  const editClient = async (formData: FormData) => {
    const { data, errors } = await manageClientData(formData);
    if (errors) {
      return { errors };
    }
    try {
      const response = await api.patch(`/clients/${data.id}`, {
        name: data.name,
        company_size: data.company_size ? +data.company_size : null,
        industry_id: data.industry ? +data.industry : null,
        description: data.description,
      });
      mutate(["clients"]);
      alert('success', 'Client data successfully updated');
      return { data: response.data };
    } catch (e: any) {
      console.error("Error", JSON.stringify(e));
      if (e.response.status === 400) {
        return {
          errors: { name: "Client name should be unique" } as Record<
            keyof Client,
            string
          >,
        };
      }
      alert('error', e, e?.response?.data?.status);
    }
  };

  const deleteClient = async (id: number) => {
    try {
      await api.delete(`/clients/${id}`);
      mutate(["clients"]);
      mutate(["users"]);
      alert('success', 'Client successfully deleted');
    } catch (e: any) {
      console.error("Error", e);
      alert('error', e, e?.response?.data?.status);
    }
  };

  return { editClient, createClient, deleteClient };
};

const manageClientData = async (formData: FormData) => {
  try {
    const data = {
      id: formData.get("id"),
      name: (formData.get("name") as string).trim(),
      company_size: formData.get("company_size"),
      industry: formData.get("industry"),
      description: (formData.get("description") as string).trim(),
    };
    const validatedData = await clientSchema.validate(data, {
      abortEarly: false,
    });
    return { data: validatedData as Client };
  } catch (e: unknown) {
    const errors = (e as ValidationError).inner.reduce(
      (acc, curr) => {
        const key = curr.path as keyof Client;
        acc[key] = curr.message;
        return acc;
      },
      {} as Record<keyof Client, string>,
    );
    return { errors };
  }
};
