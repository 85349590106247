import { Footer, HeaderLite } from "@/components";
import styles from '@/assets/styles/root.module.scss';

export default function NoPermission() {
  return (
    <>
      <HeaderLite />
      <main>
        <figure className={styles.wrapper}>
          <img src="/no-permission.svg" alt="no-permission" />
          <figcaption className={styles.content}>
            <p className={styles.msg}>
              <span>Unfortunately, you don't have access to this service.</span>
              <span>Please contact administrator for assistance.</span>
            </p>
          </figcaption>
        </figure>        
      </main>
      <Footer />
    </>
  );
}