import { useStore } from "@/context/headerContext";
import { OverflowTooltip } from "../ui-components-library";
import styles from "./style.module.scss";

export interface TitleBlockProps {
  title?: string;
  actions?: React.ReactNode;
}

const Title = ({ title, actions }: TitleBlockProps) => {
  const [data] = useStore((store) => store);
  return (
    <div className={styles.bottom}>
      <h1 className={styles.title}>
        <OverflowTooltip>{title || data.title}</OverflowTooltip>
      </h1>
      <div className={styles.action}>{actions || data.actions}</div>
    </div>
  );
};

export default Title;
