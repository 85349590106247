import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useUpdateHeaderData } from "@/hooks/useUpdateHeaderData";
import { useMutateRegion} from "@/hooks/useRegions";
import { ConfirmModal, Table, PageSpinner } from "@/components";
import { Actions } from "./Actions";
import { RegionSidebar } from "./sidebar/Region";
import { Region } from "@/types";
import styles from "./style.module.scss";

interface Props {
  data: Region[];
}

enum QUERY {
  NAME = "region_name",
  MANAGE_REGION = "manage_region",
}

export const RegionsTab = ({ data }: Props) => {
  const [regions, setRegions] = useState(data);
  const [isLoading, setLoading] = useState(false);
  const [toDelete, setToDelete] = useState<number | undefined>();

  const { deleteRegion } = useMutateRegion();

  const [searchParams, setSearchParams] = useSearchParams();

  const manageRegion = searchParams.get(QUERY.MANAGE_REGION);
  const urlFilters = searchParams.get(QUERY.NAME);
  const activeFilters = urlFilters
    ? { name: urlFilters.split(",") }
    : undefined;

  const manageRegionData = manageRegion
    ? regions.find((region) => region.id === +manageRegion)
    : null;

  useUpdateHeaderData(
    "regions",
    <Actions action="region" query={QUERY.MANAGE_REGION} />,
  );

  const handleFiltering = useCallback(
    (key: keyof Region, value: Array<string | number>) => {
      setSearchParams((query) => {
        if (value.length) {
          query.set(QUERY.NAME, value.join(","));
        } else {
          query.delete(String(key));
        }
        return query;
      });
    },
    [setSearchParams],
  );

  const handleRegionEdit = ({ id }: Region) => {
    setSearchParams((query) => {
      query.set(QUERY.MANAGE_REGION, String(id));
      return query;
    });
  };

  const handleCloseSidebar = () => {
    setSearchParams((query) => {
      query.delete(QUERY.MANAGE_REGION);
      return query;
    });
  };

  const handleSubmit = (data: Region, operation: "edit" | "create") => {
    setRegions((state) => {
      if (operation === "create") {
        return [data, ...state];
      }
      const idx = state.findIndex((item) => item.id === +data.id);
      const dataToEdit = [...state];
      dataToEdit[idx] = { ...dataToEdit[idx], ...data };
      return dataToEdit;
    });
    handleCloseSidebar();
  };

  const ColumnWithAction = ({
    row,
    children,
  }: {
    row: Region;
    children: React.ReactNode;
  }) => <div onClick={() => handleRegionEdit(row)}>{children}</div>;

  const handleRegionDelete = async (id: number) => {
    confirmModalToggle();
    setLoading(true);
    await deleteRegion(id);
    setRegions((state) => {
      return state.filter((item) => item.id !== id);
    });
    setLoading(false);
  };

  const confirmModalToggle = (client?: Region) =>
    setToDelete(client?.id as number | undefined);

  return (
    <>
      <section className={styles.tabWrapper}>
        <div className={styles.number}>{regions.length} clients</div>
        <Table
          data={regions}
          columns={columns}
          emptyMsg="There are no regions to display yet."
          config={{
            columnWrapper: {
              name: ColumnWithAction,
              code: ColumnWithAction,
              description: ColumnWithAction,
            },
            columnRenderer: {
              description: emptyModifier,
            },
            activeFilters,
            onFilter: handleFiltering,
            sortBy: {
              name: true,
            },
            defaultSortByColumn: "name",
            filterBy: {
              name: true,
            },
            actions: [
              {
                label: "Edit Region",
                onSelect: handleRegionEdit,
              },
              {
                label: "Delete Region",
                onSelect: confirmModalToggle,
              },
            ],
            defaultOrder: "asc",
            styles: {
              name: { width: "25%" },
              code: { width: "20%" },
              description: { width: "40%" },
            },
          }}
        />
      </section>
      {toDelete && (
        <ConfirmModal
          onCancel={confirmModalToggle}
          onSuccess={() => handleRegionDelete(toDelete)}
          successContent="Delete"
          title={`You are about to delete the region ${regions.find((client) => client.id === toDelete)?.name}`}
          content="Once this action is completed, all region-related data will not be available in the system."
        />
      )}
      {manageRegion && (
        <RegionSidebar
          onClose={handleCloseSidebar}
          onSubmit={handleSubmit}
          region={manageRegionData}
        />
      )}
      {isLoading && <PageSpinner isOpen />}
    </>
  );
};

const emptyModifier = (value: string) => value || "--";

const columns = {
  name: "Region Name",
  code: "Region Code",
  description: "Description",
};
