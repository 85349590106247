import { Button, Dialog } from "ui-components-library";
import styles from "./style.module.scss";

interface Props {
  title?: string;
  content?: React.ReactNode;
  onClose?: () => void;
  onCancel: () => void;
  onSuccess: () => void;
  cancelContent?: React.ReactNode;
  successContent?: React.ReactNode;
}

export const ConfirmModal = ({
  title,
  content,
  onCancel,
  onSuccess,
  onClose = onCancel,
  cancelContent = "Cancel",
  successContent = "Yes",
}: Props) => {
  return (
    <Dialog onClose={onClose} dissableClickOutside>
      <article className={styles.dialog}>
        {title && (
          <header>
            <h3>{title}</h3>
          </header>
        )}
        <div className={styles.content}>{content}</div>
        <footer>
          <Button variant="secondary" onClick={onCancel}>
            {cancelContent}
          </Button>
          <Button onClick={onSuccess}>{successContent}</Button>
        </footer>
      </article>
    </Dialog>
  );
};
