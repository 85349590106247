import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { AuthProvider } from "./context/AuthContext";
import Admin from "./pages/admin";
import Layout from "./components/Layout";
import { PageSpinner, PrivateRoute } from "./components";

const SelectApp = lazy(() => import('./pages/select-app'));
const Error = lazy(() => import('./pages/error'));
const NotFound = lazy(() => import('./pages/not-found'));
const NoPermission = lazy(() => import('./pages/no-permission'));

export const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    children: [
      {
        path: "/",
        element: <SelectApp />,
      },
      {
        element: (
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        ),
        children: [
          {
            path: "/administration",
            element: <Admin />,
          },
        ],
      },
    ],
    errorElement: <Error />,
  },
  {
    path: "/Login",
    element: <LoginCallback loadingElement={<PageSpinner isOpen />} />,
  },
  {
    path: "/Logout",
    element: <PageSpinner isOpen />,
  },
  {
    path: '/no-permission',
    element: <NoPermission />
  },
  {
    path: "/not-found",
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
