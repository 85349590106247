import { Link } from "react-router-dom";
import TitleBlock, { TitleBlockProps } from "./titleBlock";
import { Avatar } from "../Avatar";
import { SelectApp } from "../SelectApp";
import styles from "./style.module.scss";

const Header = (props: TitleBlockProps) => (
  <header className={styles.header}>
    <div className={styles.content}>
      <div className={styles.top}>
        <Link to="/">
          <figure className={styles.logo}>
            <img src="/CBRE_green.svg" alt="CBRE logo"width={75} height={19} loading="eager" />
            <figcaption>Portfolio Optimization</figcaption>
          </figure>
        </Link>
        <div className={styles.blockWrapper}>
          <Avatar />
          <SelectApp />
        </div>
      </div>
      <TitleBlock {...props} />
    </div>
  </header>
);

export default Header;
