import { useState } from "react";
import { SideBar } from "ui-components-library";
import { ConfirmModal } from "../ConfirmModal";

interface Props {
  onClose: () => void;
  children: React.ReactNode;
  modified?: boolean;
}

const SidebarComponent = ({ onClose, children, modified = false }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleConfirmationDialog = () => setIsOpen((state) => !state);

  const handleShowConfirmationDialog = () => {
    if (modified) {
      toggleConfirmationDialog();
    } else {
      onClose();
    }
  };

  return (
    <>
      <SideBar isOpen onClose={handleShowConfirmationDialog}>{children}</SideBar>
      {isOpen && (
        <ConfirmModal
          onCancel={onClose}
          onClose={toggleConfirmationDialog}
          onSuccess={toggleConfirmationDialog}
          title="You have unsaved changes"
          content="Changes will be lost if you leave without saving."
          successContent="Return to editing"
          cancelContent="Discard changes and leave"
        />
      )}
    </>
  );
};

export { SidebarComponent as Sidebar };
