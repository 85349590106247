import { Link } from "react-router-dom";
import { Copy } from "..";
import styles from "./style.module.scss";

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.left}>
      <p>Copyright &copy; {new Date().getFullYear()} CBRE Inc.</p>
      <p className={styles.copy}>
        Version {process.env.REACT_APP_APPLICATION_VERSION || "none"}
        <Copy text={process.env.REACT_APP_APPLICATION_VERSION || "none"} />
      </p>
    </div>
    <nav className={styles.right}>
      <Link to="https://www.cbre.com/about/privacy-policy" target="_blank">
        Privacy Policy
      </Link>
      <Link
        to="https://www.cbre.com/about/disclaimer-terms-of-use"
        target="_blank"
      >
        Terms & Conditions
      </Link>
      <Link to="mailto:DNT-US-TGMProductFeedback@cbre.com" target="_blank">
        Contact Us
      </Link>
    </nav>
  </footer>
);

export default Footer;
