import { useState } from "react";
import { useMutateUser } from "@/hooks/useUsers";
import { useModified } from "@/hooks/useModified";
import { formAction } from "@/lib/helper/formAction";
import {
  Sidebar,
  Select,
  Switch,
  TextInput,
  MultiSelect,
  Button,
} from "@/components";
import { SelectOption, User, action } from "@/types";
import styles from "./style.module.scss";

interface FormState extends Omit<User, "id" | "last_seen" | "role_id"> {
  title: string;
  action: (data: FormData) => Promise<any>;
  id?: number | string;
  is_active: boolean;
}

interface Props {
  user?: any | null;
  roles: SelectOption<string>[];
  clients: SelectOption<string>[];
  regions: SelectOption<string>[];
  onSubmit: (data: User, operation: action) => void;
  onClose: () => void;
}

interface ComponentProps {
  data: FormState;
  roles: SelectOption<string>[];
  clients: SelectOption<string>[];
  regions: SelectOption<string>[];
  handleChange: (
    key: keyof FormState,
    value: boolean | string | number | Array<string | number>,
  ) => void;
}

export const UserSidebar = ({ user, onClose, onSubmit, ...props }: Props) => {
  const { createUser, editUser } = useMutateUser();
  const data: FormState = user
    ? {
        title: "Edit User",
        action: formAction(editUser, onSubmit, "edit"),
        ...user,
        role: props.roles.find((role) => role.label === user.role)?.value || "",
        clients: user.clients.map(
          (client) =>
            props.clients.find((item) => item.label === client)?.value || "",
        ),
          regions: user.regions.map(
              (region) =>
                  props.regions.find((item) => item.label === region)?.value || "",
          ),
      }
    : {
        title: "New User",
        action: formAction(createUser, onSubmit, "create"),
        name: "",
        email: "",
        role: "",
        clients: [],
        regions: [],
        is_active: true,
      };

  const [modified, onChange] = useModified(data);

  return (
    <Sidebar onClose={onClose} modified={modified}>
      <article className={styles.wrapper}>
        <Content {...props} data={data} handleChange={onChange} />
      </article>
    </Sidebar>
  );
};

const Content = ({ clients, regions, roles, handleChange, data }: ComponentProps) => {
  const [errors, setError] = useState<Record<keyof FormState, string>>(
    {} as Record<keyof FormState, string>,
  );

  const onChangeHandle = (
    key: keyof FormState,
    value: boolean | string | number | Array<string | number>,
  ) => {
    handleChange(key, value);
    setError((errorState) => {
      const clone = { ...errorState };
      delete clone[key];
      return clone;
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const validationErrors = await data.action(formData);
    if (validationErrors) {
      setError(validationErrors);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <header>
        <Button type="submit">Save</Button>
        <h2 className={styles.title}>{data.title}</h2>
      </header>
      <div className={`${styles.section} ${styles.content}`}>
        <input type="text" readOnly hidden value={data.id} id="id" name="id" />
        <TextInput
          label="User Name"
          name="name"
          defaultValue={data.name}
          error={!!errors?.name}
          helperText={errors?.name}
          onChange={(e) => onChangeHandle("name", e.target.value)}
        />
        <TextInput
          label="Email"
          name="email"
          required
          defaultValue={data.email}
          error={!!errors?.email}
          helperText={errors?.email}
          onChange={(e) => onChangeHandle("email", e.target.value)}
        />
      </div>
      <div className={styles.section}>
        <h6 className={styles.subtitle}> Select Role *</h6>
        <Select
          label="Role"
          name="role"
          options={roles}
          defaultValue={data.role}
          required
          error={!!errors?.role}
          helperText={errors?.role}
          onChange={(value) => onChangeHandle("role", value)}
        />
      </div>
      <div className={styles.section}>
        <h6 className={`${styles.subtitle} ${styles.mb10}`}>Client Mapping</h6>
        <MultiSelect
          name="clients"
          defaultSelected={data.clients}
          actionLabel="Add Client"
          options={clients}
          onChange={(value) => onChangeHandle("clients", value)}
        />
      </div>
        <div className={styles.section}>
            <h6 className={`${styles.subtitle} ${styles.mb10}`}>Region Mapping</h6>
            <MultiSelect
                name="regions"
                defaultSelected={data.regions}
                actionLabel="Add Region"
                options={regions}
                onChange={(value) => onChangeHandle("regions", value)}
            />
        </div>
      <div className={styles.section}>
        <label className={styles.switch} htmlFor="is_active">
          <h6 className={styles.subtitle}>Activate User</h6>
          <Switch
            defaultChecked={data.is_active}
            name="is_active"
            onCheckedChange={(value) => onChangeHandle("is_active", value)}
          />
        </label>
      </div>
    </form>
  );
};
