import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useCurrentUser } from "@/hooks/useUsers";
import { useAlert } from "./AlertContext";
import { useApi } from "@/lib/api";
import { User } from "@/types";
import NoPermission from "@/pages/no-permission";

type AuthContextType = {
  user: User | undefined;
  logout: () => void;
};

const initialData = {
  id: NaN,
  name: "",
  email: "",
  is_active: true,
  created_at: "",
  updated_at: "",
  last_seen: "",
  creator: "",
  updater: "",
  applications: [],
  role: "",
  clients: [],
  regions: [],
};

export const AuthContext = createContext<AuthContextType>({
  user: initialData,
  logout: () => {},
});

const { Provider } = AuthContext;

export const AuthProvider = () => {
  const [user, setUser] = useState<User>(initialData);
  const [error, setError] = useState(null);

  const api = useApi();
  const alert = useAlert();
  const { oktaAuth, authState } = useOktaAuth();
  const { getMe } = useCurrentUser();

  const injectAuthToken = (type: string, token: string) => {
    api.defaults.headers.common.Authorization = `${type} ${token}`;
  };

  const logout = useCallback(async () => {
    await oktaAuth.signOut();
    window.location.replace(
      "https://login.microsoftonline.com/common/oauth2/logout",
    );
  }, [oktaAuth]);

  const login = async () => {
    await oktaAuth.signInWithRedirect({
      originalUri: window.location.pathname,
    });
  };

  const updateTokenByExpireTime = (expiresAt: number) => {
    const timeToExpire = expiresAt * 1000 - Date.now() - 10000;
    if (timeToExpire > 0) {
      setTimeout(updateToken, timeToExpire);
    } else {
      updateToken();
    }
  };

  const updateToken = () => {
    oktaAuth.tokenManager.renew("accessToken").then((token: any) => {
      injectAuthToken(token?.tokenType, token?.accessToken);
      updateTokenByExpireTime(token?.expiresAt);
    });
  };

  const updateClientData = async () => {
    try {
      injectAuthToken(
        authState!.accessToken?.tokenType!,
        authState!.accessToken?.accessToken!,
      );
      updateTokenByExpireTime(authState!.accessToken?.expiresAt!);
      const response = await getMe();
      if (response.is_active) {
        setUser(response);
      } else {
        throw new Error('User is deactivated!');
      }
    } catch (e: any) {
      console.error('Error: ', e);
      setError(e as any);
      alert('error', e);
    }
  };

  const value = useMemo(() => ({ user, logout }), [user, logout]);

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      login();
    }
    if (authState && authState.isAuthenticated) {
      updateClientData();
    }
  }, [authState]);

  if (error) {
    return <NoPermission />
  }

  return (
    <Provider value={value}>
      <Outlet />
    </Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
