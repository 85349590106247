import { object, string } from "yup";

export const clientSchema = object({
  id: string().optional().nullable(),
  name: string().max(255).required().label("Client Name"),
  company_size: string()
    .nullable()
    .optional()
    .matches(/^(?:[0-9]*)?$/, "Company Size can only be possitive number")
    .max(9, "Company Size is too large. The maximum number is 100 million.")
    .label("Company Size"),
  industry: string().min(0).optional().label("Industry"),
  description: string().min(0).max(1024).optional().label("Description"),
});
