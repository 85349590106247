import React from "react";
import { Budge, Tooltip } from "..";
import styles from "./style.module.scss";

const RowWithBudge = (items: string[], ref: any) => {
  const { budge, content } = handleCalculate(ref.current?.offsetWidth, items);

  return (
    <div className={styles.wrapper}>
      {content}
      {budge > 0 && (
        <Tooltip tooltip={items.join(", ")}>
          <span>
            <Budge color="black" background="#CAD1D3">
              +{budge}
            </Budge>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default RowWithBudge;

const handleCalculate = (wrapperSize: number, items: string[]) => {
  const allSymbols = items.join(", ");
  const totalLength = allSymbols.length * 12; // average length in pixels

  if (wrapperSize > totalLength) {
    return {
      budge: 0,
      content: allSymbols || "--",
    };
  }

  const a = Math.floor(wrapperSize / 12);
  const b = allSymbols.slice(0, a);
  const number = (b.match(new RegExp(",", "g")) || []).length;

  if (number <= 1) {
    return {
      budge: 0,
      content: allSymbols,
    };
  }

  return {
    budge: items.length - number,
    content: `${items.slice(0, number).join(", ")} ...`,
  };
};
