import { Security } from "@okta/okta-react";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";

export const OktaWrapper = ({ children }: { children: React.ReactNode }) => {
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER_URL ?? "",
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID ?? "",
    scopes: ["openid", "email"],
    redirectUri: `${window.location.origin}/Login`,
    postLogoutRedirectUri: `${window.location.origin}/Logout`,
    pkce: true,
  });

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) =>
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin),
    );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
