import { TabsWithClientActions } from "@/components";
import { ClientsTab } from "@/modules/admin/ClientTab";
import { RegionsTab } from "@/modules/admin/RegionTab";
import { UsersTab } from "@/modules/admin/UsersTab";
import { AdminLoading } from "@/modules/admin/AdminLoading";
import { useUpdateHeaderTitle } from "@/hooks/useUpdateHeaderData";
import { useUsers } from "@/hooks/useUsers";
import { useClients } from "@/hooks/useClients";
import { useRoles } from "@/hooks/useRoles";
import { useIndastries } from "@/hooks/useIndastry";
import {useRegions} from "@/hooks/useRegions";

export default function Admin() {
  const { users, userErrors } = useUsers();
  const { clients, clientErrors } = useClients();
  const { regions, regionErrors } = useRegions();
  const { roles, roleErrors } = useRoles();
  const { industries, industryErrors } = useIndastries();
  const errors = userErrors || clientErrors || roleErrors || industryErrors || regionErrors;

  useUpdateHeaderTitle("Administration");

  if (errors) {
    throw new Error(errors);
  }

  if (!(users && clients && roles && industries && regions)) {
    return <AdminLoading />;
  }

  const clientOptions = clients.map((client) => ({
    label: client.name,
    value: client.id.toString(),
  }));
  const regionOptions = regions.map((region) => ({
    label: region.name,
    value: region.id.toString(),
  }));

  const tabData = [
    {
      label: "Users",
      value: "users",
      content: (
        <UsersTab
          clientOptions={clientOptions}
          regionOptions={regionOptions}
          roleOptions={roles}
          data={users}
        />
      ),
    },
    {
      label: "Clients",
      value: "clients",
      content: <ClientsTab data={clients} industryOptions={industries} />,
    },
    {
      label: "Regions",
      value: "regions",
      content: <RegionsTab data={regions} />,
    },
  ];

  return <TabsWithClientActions defaultValue="users" data={tabData} />;
}
