import styles from "./style.module.scss";

const HeaderLite = () => (
  <header className={styles.header}>
    <div className={styles.content}>
      <div className={styles.top}>
        <div className={styles.blockWrapper}>
          <figure className={styles.logo}>
            <img src="/CBRE_green.svg" alt="CBRE logo"width={75} height={19} loading="eager" />
            <figcaption>Portfolio Optimization</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </header>
);

export default HeaderLite;
