import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";

class ApiClient {
  private static instance: ApiClient;

  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: new URL(process.env.REACT_APP_BACKEND_BASE_API_URL as string)
        .origin,
    });
    this.client.interceptors.request.use(this.urlInjection);
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }

    return ApiClient.instance;
  }

  getClient() {
    return this.client;
  }

  urlInjection(request: InternalAxiosRequestConfig<any>) {
    request.baseURL += "/api/v1";
    return request;
  }
}

export const useApi = () => {
  const instance: ApiClient = ApiClient.getInstance();
  return instance.getClient();
};
