import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { AlertProvider } from "./context/AlertContext";
import { OktaWrapper, PageSpinner } from "./components";
import { router } from "./router";
import "./assets/styles/globals.scss";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <OktaWrapper>
      <AlertProvider>
        <Suspense fallback={<PageSpinner isOpen />}>
          <RouterProvider router={router} />
        </Suspense>
      </AlertProvider>
    </OktaWrapper>
  </React.StrictMode>,
);
