import useSWR from "swr";
import { useAlert } from "@/context/AlertContext";
import { useApi } from "@/lib/api";
import { Roles, SelectOption } from "@/types";

export const useRoles = () => {
  const api = useApi();
  const alert = useAlert();

  const getRolesList = async () => {
    const { data } = await api.get<Roles[]>("/roles");
    return data.map((role) => ({
      label: role.name,
      value: role.id.toString(),
    })) as SelectOption<string>[];
  };

  const { data: roles, error: roleErrors } = useSWR(
    ["roles"],
    getRolesList,
    {
      onError: (e: any) => alert('error', e, e?.response?.data?.status),
    }
  );

  return { roles, roleErrors };
};
