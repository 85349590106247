import useSWR from "swr";
import { useAlert } from "@/context/AlertContext";
import { useApi } from "@/lib/api";
import { SelectOption } from "@/types";

export const useIndastries = () => {
  const api = useApi();
  const alert = useAlert();
  
  const getIndustriesList = async () => {
    const { data } = await api.get("/industries");
    return data.map((industry) => ({
      label: industry.name,
      value: industry.id.toString(),
    })) as SelectOption<string>[];
  };

  const { data: industries, error: industryErrors } = useSWR(
    ["industries"],
    getIndustriesList,
    {
      onError: (e: any) => alert('error', e, e?.response?.data?.status),
    }
  );

  return { industries, industryErrors };
};
