import React from "react";
import createFastContext from "./createFastContext";

interface Context {
  title: string;
  actions: React.ReactNode;
}

const initialState: Context = {
  title: "",
  actions: null,
};

const { Provider, useStore } = createFastContext(initialState);
export { Provider, useStore };
