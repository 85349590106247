import skeleton from "@/assets/styles/skeleton.module.scss";
import styles from "./skeleton.module.scss";

export const AdminLoading = () => (
  <div className={styles.wrapper}>
    <div className={`${skeleton.style} ${styles.tab}`} />
    <div>
      <div className={`${styles.head} ${skeleton.style}`} />
      {Array(10)
        .fill(0)
        .map((_, i) => (
          <div key={i} className={`${styles.row} ${skeleton.style}`} />
        ))}
    </div>
  </div>
);
