import { Outlet } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import { Provider } from "@/context/headerContext";
import { Header, Footer, PageSpinner } from "../";

export default function Layout() {
  const { user } = useAuth();
  return (
    <Provider>
      <Header />
      <main>{user?.id ? <Outlet /> : <PageSpinner isOpen />}</main>
      <Footer />
    </Provider>
  );
}
