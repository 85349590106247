import { useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import { PageSpinner } from "../ui-components-library";
import { APPS } from "@/types";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!user?.id) {
    return <PageSpinner isOpen />;
  }

  if (!user.applications.includes(APPS.PLATFORM)) {
    navigate("/no-permission");
  }

  return children;
};
