import { Menu, IconButton } from "@/components";
import { useAuth } from "@/context/AuthContext";
import skeleton from "@/assets/styles/skeleton.module.scss";
import styles from "./style.module.scss";

const Avatar = () => {
  const { user, logout } = useAuth();

  if (!user?.id) {
    return (
      <div className={styles.avatar}>
        <span className={`${styles.icon} ${skeleton.style}`} />
      </div>
    );
  }
  const { name, email, role } = user;

  const iconLable = (name || email)
    .split(" ")
    .map((word) => word[0])
    .join("");

  return (
    <div className={styles.avatar}>
      <div className={styles.user}>
        <span className={styles.name}>{name || email}</span>
        <span className={styles.role}>{role}</span>
      </div>
      <Menu options={[{ label: "Log out", onSelect: logout }]}>
        <IconButton>
          <span className={styles.icon}>{iconLable}</span>
        </IconButton>
      </Menu>
    </div>
  );
};

export default Avatar;
