import { useState } from "react";
import { useModified } from "@/hooks/useModified";
import { useMutateRegion} from "@/hooks/useRegions";
import { formAction } from "@/lib/helper/formAction";
import { Sidebar, TextArea, TextInput, Button } from "@/components";
import { Region, action } from "@/types";
import styles from "./style.module.scss";

interface FormState extends Omit<Region, "id"> {
  title: string;
  action: (data: FormData) => Promise<any>;
  id?: number | string;
}

interface Props {
  region?: Region | null;
  onSubmit: (data: Region, operation: action) => void;
  onClose: () => void;
}

interface ComponentProps {
  data: FormState;
  handleChange: (
    key: keyof FormState,
    value: boolean | string | number | Array<string | number>,
  ) => void;
}

export const RegionSidebar = ({
  region,
  onClose,
  onSubmit,
  ...props
}: Props) => {
  const { editRegion, createRegion } = useMutateRegion();
  const data: FormState = region
    ? {
        title: "Edit Region",
        action: formAction(editRegion, onSubmit, "edit"),
        ...region
      }
    : {
        title: "New Region",
        action: formAction(createRegion, onSubmit, "create"),
        name: "",
        code: "",
        description: "",
      };

  const [modified, onChange] = useModified(data);

  return (
    <Sidebar onClose={onClose} modified={modified}>
      <article className={styles.wrapper}>
        <Content {...props} data={data} handleChange={onChange} />
      </article>
    </Sidebar>
  );
};

const Content = ({ data, handleChange }: ComponentProps) => {
  const [errors, setError] = useState<Record<keyof FormState, string>>(
    {} as Record<keyof FormState, string>,
  );

  const onChangeHandle = (
    key: keyof FormState,
    value: boolean | string | number | Array<string | number>,
  ) => {
    handleChange(key, value);
    setError((errorState) => {
      const clone = { ...errorState };
      delete clone[key];
      return clone;
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const validationErrors = await data.action(formData);
    if (validationErrors) {
      setError(validationErrors);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <header>
        <Button type="submit">Save</Button>
        <h2 className={styles.title}>{data.title}</h2>
      </header>
      <div className={styles.content}>
        <input type="text" readOnly hidden value={data.id} id="id" name="id" />
        <TextInput
          label="Region Name"
          name="name"
          required
          defaultValue={data.name}
          error={!!errors?.name}
          helperText={errors?.name}
          onChange={(e) => onChangeHandle("name", e.target.value)}
        />
        <div className={styles.row}>
          <TextInput
            label="Region Code"
            name="code"
            required
            defaultValue={data.code}
            error={!!errors?.code}
            helperText={errors?.code}
            onChange={(e) => onChangeHandle("code", e.target.value)}
          />
        </div>
        <TextArea
          label="Description"
          name="description"
          rows={4}
          defaultValue={data.description}
          error={!!errors?.description}
          helperText={errors?.description}
          onChange={(e) => onChangeHandle("description", e.target.value)}
        />
      </div>
    </form>
  );
};
