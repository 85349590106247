import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useUpdateHeaderData } from "@/hooks/useUpdateHeaderData";
import { useMutateClient } from "@/hooks/useClients";
import { ConfirmModal, Table, PageSpinner } from "@/components";
import { Actions } from "./Actions";
import { ClientSidebar } from "./sidebar/Client";
import { Client, SelectOption } from "@/types";
import styles from "./style.module.scss";

interface Props {
  data: Client[];
  industryOptions: SelectOption<string>[];
}

enum QUERY {
  NAME = "client_name",
  MANAGE_CLIENT = "manage_client",
}

export const ClientsTab = ({ data, industryOptions }: Props) => {
  const [clients, setClients] = useState(data);
  const [isLoading, setLoading] = useState(false);
  const [toDelete, setToDelete] = useState<number | undefined>();

  const { deleteClient } = useMutateClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const manageClient = searchParams.get(QUERY.MANAGE_CLIENT);
  const urlFilters = searchParams.get(QUERY.NAME);
  const activeFilters = urlFilters
    ? { name: urlFilters.split(",") }
    : undefined;

  const manageClientData = manageClient
    ? clients.find((client) => client.id === +manageClient)
    : null;

  useUpdateHeaderData(
    "clients",
    <Actions action="client" query={QUERY.MANAGE_CLIENT} />,
  );

  const handleFiltering = useCallback(
    (key: keyof Client, value: Array<string | number>) => {
      setSearchParams((query) => {
        if (value.length) {
          query.set(QUERY.NAME, value.join(","));
        } else {
          query.delete(String(key));
        }
        return query;
      });
    },
    [setSearchParams],
  );

  const handleClientEdit = ({ id }: Client) => {
    setSearchParams((query) => {
      query.set(QUERY.MANAGE_CLIENT, String(id));
      return query;
    });
  };

  const handleCloseSidebar = () => {
    setSearchParams((query) => {
      query.delete(QUERY.MANAGE_CLIENT);
      return query;
    });
  };

  const handleSubmit = (data: Client, operation: "edit" | "create") => {
    setClients((state) => {
      if (operation === "create") {
        return [data, ...state];
      }
      const idx = state.findIndex((item) => item.id === +data.id);
      const dataToEdit = [...state];
      dataToEdit[idx] = { ...dataToEdit[idx], ...data };
      return dataToEdit;
    });
    handleCloseSidebar();
  };

  const ColumnWithAction = ({
    row,
    children,
  }: {
    row: Client;
    children: React.ReactNode;
  }) => <div onClick={() => handleClientEdit(row)}>{children}</div>;

  const handleClientDelete = async (id: number) => {
    confirmModalToggle();
    setLoading(true);
    await deleteClient(id);
    setClients((state) => {
      return state.filter((item) => item.id !== id);
    });
    setLoading(false);
  };

  const confirmModalToggle = (client?: Client) =>
    setToDelete(client?.id as number | undefined);

  return (
    <>
      <section className={styles.tabWrapper}>
        <div className={styles.number}>{clients.length} clients</div>
        <Table
          data={clients}
          columns={columns}
          emptyMsg="There are no clients to display yet."
          config={{
            columnWrapper: {
              name: ColumnWithAction,
              company_size: ColumnWithAction,
              industry: ColumnWithAction,
              description: ColumnWithAction,
            },
            columnRenderer: {
              company_size: emptyModifier,
              industry: emptyModifier,
              description: emptyModifier,
            },
            activeFilters,
            onFilter: handleFiltering,
            sortBy: {
              name: true,
            },
            defaultSortByColumn: "name",
            filterBy: {
              name: true,
            },
            actions: [
              {
                label: "Edit Client",
                onSelect: handleClientEdit,
              },
              {
                label: "Delete Client",
                onSelect: confirmModalToggle,
              },
            ],
            defaultOrder: "asc",
            styles: {
              name: { width: "20%" },
              company_size: { width: "10%" },
              industry: { width: "20%" },
              description: { width: "35%" },
            },
          }}
        />
      </section>
      {toDelete && (
        <ConfirmModal
          onCancel={confirmModalToggle}
          onSuccess={() => handleClientDelete(toDelete)}
          successContent="Delete"
          title={`You are about to delete the client ${clients.find((client) => client.id === toDelete)?.name}`}
          content="Once this action is completed, all client-related data will not be available in the system."
        />
      )}
      {manageClient && (
        <ClientSidebar
          onClose={handleCloseSidebar}
          onSubmit={handleSubmit}
          client={manageClientData}
          industries={industryOptions}
        />
      )}
      {isLoading && <PageSpinner isOpen />}
    </>
  );
};

const emptyModifier = (value: string) => value || "--";

const columns = {
  name: "Client Name",
  company_size: "Company Size",
  industry: "Industry",
  description: "Description",
};
