import { Apps } from '@/assets/icons';
import { useAuth } from '@/context/AuthContext';
import { APPS } from '@/types';
import { IconButton, Menu } from '../ui-components-library';
import styles from './style.module.scss';

const path: Record<string, Record<string, string>> = {
  [APPS.OP]: {
    label: 'Occupancy Planner',
    link: `${window.location.origin}/occupancy-planner`,
  },
  [APPS.TS]: {
    label: 'Portfolio',
    link: `${window.location.origin}/portfolio`,
  },
  [APPS.MC]: {
    label: 'Market Canvas',
    link: new URL(process.env.REACT_APP_MARKET_CANVAS_URL as string).origin,
  },
}

export const SelectApp = () => {
  const { user } = useAuth();
  
  if (user && user.applications.length > 1) {
    const handleSelect = (path: string) => window.location.replace(path);
    const options = [APPS.OP, APPS.TS, APPS.MC].map((app) => {
      if (user.applications.includes(app)) {
        return { label: path[app].label, onSelect: () => handleSelect(path[app].link) };
      }
    });

    return (
      <>
        <span className={styles.bar} />
        <Menu
          // @ts-ignore
          options={options}
        >
          <IconButton>
            <Apps />
          </IconButton>
        </Menu>
      </>
    );
  }

  return null;
};